import { useMutation } from 'react-query'
import AWS from 'aws-sdk'
import { Auth } from 'aws-amplify'
import CryptoJS from 'crypto-js'
import {
	loadActivityCounts,
	loadFileFilterList,
	loadQueueList,
	loadUserIpAddress,
	loadS3Creds,
	updateMentionUnreads,
	onMentionSendMail,
	loadSlotsList,
	loadFileReferences,
	loadCoworkerList,
	toGetBucketName,
	loadUrlParamsLookup,
	loadRoleList,
	loadAssetTypeList,
	createCustomerAsset,
} from '../services/shared.service'
import { getLocalAuthDetails } from '../utils/common.util'
import { AUTH_LOGIN_MODE, AUTH_SESSION_DATA } from '../utils/constant'

export function useLoadQueueList() {
	return useMutation(async (reqPayload = {}) => {
		try {
			let payload = {
				...reqPayload,
				...getLocalAuthDetails(),
				flag: 0,
				page_start: 0,
				page_limit: 100,
				service_version: 1.0,
				app_version: 1.0,
				device_os_id: 5,
			}
			let res = await loadQueueList(payload)
			if (res.status === 200) {
				if (res.data.response && res.data.response.length > 0) {
					return res.data.response
				} else {
					return []
				}
			} else {
				throw new Error('Something went wrong!')
			}
		} catch (error) {
			return error
		}
	})
}

export function useLoadFileFilterList() {
	return useMutation(async () => {
		try {
			let payload = {
				...getLocalAuthDetails(),
				flag_participanting: 0,
				flag: 0,
				limit_value: 30,
				page_limit: 30,
				page_start: 0,
			}
			let res = await loadFileFilterList(payload)
			if (res.status === 200) {
				if (res.data.response && res.data.response.length > 0) {
					return res.data.response
				} else {
					return []
				}
			} else {
				throw new Error('Something went wrong!')
			}
		} catch (error) {
			return error
		}
	})
}

export function useLoadActivityCounts() {
	return useMutation(async params => {
		let res = await loadActivityCounts({
			...params,
			...getLocalAuthDetails(),
			limit_value: 30,
			start_from: 0,
		})
		if (res.data.response && res.data.response.length) {
			return res.data.response[0]
		} else {
			return {}
		}
	})
}

export function useLoadUserIpAddress() {
	return useMutation(async params => {
		try {
			let res = await loadUserIpAddress()
			return res.data.ip
		} catch (error) {
			console.log(error)
			return error
		}
	})
}

const onSetOTPAwsConfig = ({ idToken }) => {
	// Set the region where your identity pool exists (us-east-1, eu-west-1)
	AWS.config.region = process.env.REACT_APP_AWS_REGION

	// Configure the credentials provider to use your identity pool
	AWS.config.credentials = new AWS.CognitoIdentityCredentials({
		IdentityPoolId: process.env.REACT_APP_OTP_IDENTITY_POOL_ID,
		Logins: {
			[`cognito-idp.ap-south-1.amazonaws.com/${process.env.REACT_APP_OTP_USER_POOL_ID}`]:
				idToken.jwtToken,
		},
	})
}

const onSetQRAwsConfig = ({ idToken }) => {
	// Set the region where your identity pool exists (us-east-1, eu-west-1)
	AWS.config.region = process.env.REACT_APP_AWS_REGION

	// Configure the credentials provider to use your identity pool
	AWS.config.credentials = new AWS.CognitoIdentityCredentials({
		IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
		Logins: {
			[`cognito-idp.ap-south-1.amazonaws.com/${process.env.REACT_APP_USER_POOL_ID}`]:
				idToken.jwtToken,
		},
	})
}

const onSetMailAwsConfig = ({ idToken }) => {
	// Set the region where your identity pool exists (us-east-1, eu-west-1)
	AWS.config.region = process.env.REACT_APP_AWS_REGION

	// Configure the credentials provider to use your identity pool
	AWS.config.credentials = new AWS.CognitoIdentityCredentials({
		IdentityPoolId: process.env.REACT_APP_EMAIL_IDENTITY_POOL_ID,
		Logins: {
			[`cognito-idp.ap-south-1.amazonaws.com/${process.env.REACT_APP_EMAIL_USER_POOL_ID}`]:
				idToken.jwtToken,
		},
	})
}

const onSetAzureConfig = ({ idToken }) => {
	// Set the region where your identity pool exists (us-east-1, eu-west-1)
	AWS.config.region = process.env.REACT_APP_AWS_REGION

	// Configure the credentials provider to use your identity pool
	AWS.config.credentials = new AWS.CognitoIdentityCredentials({
		IdentityPoolId: process.env.REACT_APP_AD_IDENTITY_POOL_ID,
		Logins: {
			[`cognito-idp.ap-south-1.amazonaws.com/${process.env.REACT_APP_AD_USER_POOL_ID}`]:
				idToken.jwtToken,
		},
	})
}

export async function LoadS3CredsFiles() {
	try {
		const cognitoUser = await Auth.currentAuthenticatedUser()
		const currentSession = await Auth.currentSession()
		cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
			// console.log('session', err, session)
			const { idToken, refreshToken, accessToken } = session
			// console.log({ idToken })
			if (session?.idToken) {
				if (sessionStorage.getItem(AUTH_LOGIN_MODE)) {
					if (sessionStorage.getItem(AUTH_LOGIN_MODE) === '1') {
						onSetOTPAwsConfig(session)
					} else if (sessionStorage.getItem(AUTH_LOGIN_MODE) === '0') {
						onSetQRAwsConfig(session)
					} else if (sessionStorage.getItem(AUTH_LOGIN_MODE) === '3') {
						onSetAzureConfig(session)
					} else {
						onSetMailAwsConfig(session)
					}
				}
			}
		})
	} catch (e) {
		console.log('Unable to refresh Token', e)
	}
}

export function useLoadS3Creds() {
	return useMutation(async () => {
		try {
			if (sessionStorage.getItem(AUTH_SESSION_DATA)) {
				const token = JSON.parse(sessionStorage.getItem(AUTH_SESSION_DATA))
				if (token?.idToken) {
					if (sessionStorage.getItem(AUTH_LOGIN_MODE)) {
						if (sessionStorage.getItem(AUTH_LOGIN_MODE) === '1') {
							onSetOTPAwsConfig(token)
						} else if (sessionStorage.getItem(AUTH_LOGIN_MODE) === '0') {
							onSetQRAwsConfig(token)
						} else if (sessionStorage.getItem(AUTH_LOGIN_MODE) === '3') {
							onSetAzureConfig(token)
						} else {
							onSetMailAwsConfig(token)
						}
					}
				}
			}
		} catch (err) {
			console.error('Error', err)
			return err
		}

		// try {
		//   const auth = getLocalAuthDetails()
		//   let res = await loadS3Creds({
		//     device_os_id: 5,
		//     access_key_type_id: 1,
		//     ...auth,
		//   })
		//   const { access_key_inline_data } = res.data.response[0]
		//   const byts = CryptoJS.AES.decrypt(
		//     access_key_inline_data.toString(),
		//     process.env.REACT_APP_S3_KEY
		//   )
		//   const creds = JSON.parse(byts.toString(CryptoJS.enc.Utf8))
		//   AWS.config.update({
		//     accessKeyId: creds.access_key,
		//     secretAccessKey: creds.secret_key,
		//     region: creds.region,
		//   })
		//   config.set(creds, { freeze: false })
		//   return true
		// } catch (err) {
		//   console.error('Error', err)
		//   return err
		// }
		return true
	})
}

export function useUpdateMentionUnreads() {
	return useMutation(async params => {
		try {
			let payload = {
				...getLocalAuthDetails(),
				...params,
			}
			let res = await updateMentionUnreads(payload)
			return res.data.response
		} catch (error) {
			console.log(error)
			return error
		}
	})
}

export function useMentionsSendMail() {
	return useMutation(async params => {
		try {
			let payload = {
				...getLocalAuthDetails(),
				...params,
			}
			let res = await onMentionSendMail(payload)
			return res.data.response
		} catch (error) {
			console.log(error)
			return error
		}
	})
}

export function useLoadSlotsList() {
	return useMutation(async params => {
		try {
			let payload = {
				...getLocalAuthDetails(),
				...params,
			}
			let res = await loadSlotsList(payload)
			const list = res.data.response
			const optionList = []
			if (list.length > 0) {
				list.forEach(variant => {
					let variantData = JSON.parse(variant.account_inline_data)
					if (!variantData && variantData.timeslots) {
						variantData.timeslots.forEach(slot => {
							const { slot_duration, slot_start_time, slot_end_time } = slot
							let option = {
								label: slot.slot_start_time + ' - ' + slot.slot_end_time,
								value: slot.slot_start_time + ' - ' + slot.slot_end_time,
								details: {
									slot_duration,
									slot_start_time,
									slot_end_time,
								},
							}
							optionList.push(option)
						})
					}
				})
			}
			return optionList
		} catch (error) {
			console.log(error)
			return error
		}
	})
}

export function useLoadFileReference() {
	return useMutation(async params => {
		try {
			let payload = {
				...getLocalAuthDetails(),
				...params,
			}
			let res = await loadFileReferences(payload)
			if (res.status === 200) {
				let fileData = res.data.response.data
				fileData.map(value => {
					return {
						label: value.activity_title,
						value: {
							activity_id: value.activity_id,
							activity_title: value.activity_title,
							activity_type_id: value.activity_type_id,
							activity_type_category_id: value.activity_type_category_id,
							actvity_sub_type_id: value.activity_sub_type_id,
						},
					}
				})
				return fileData
			} else {
				return []
			}
		} catch (error) {
			console.log(error)
			return error
		}
	})
}

export function useLoadCoWorkersList() {
	return useMutation(async params => {
		try {
			let payload = {
				...getLocalAuthDetails(),
				...params,
			}
			let res = await loadCoworkerList(payload)
			if (res.status === 200) {
				return res.data.response
			} else {
				return []
			}
		} catch (error) {
			console.log(error)
			return error
		}
	})
}

export function useLoadRoleList() {
	return useMutation(async params => {
		try {
			let payload = {
				...params,
			}
			let res = await loadRoleList(payload)
			if (res.status === 200) {
				return res.data.response
			} else {
				return null
			}
		} catch (error) {
			console.log(error)
			return error
		}
	})
}

export function useLoadBucketName() {
	return useMutation(async params => {
		try {
			let payload = {
				...getLocalAuthDetails(),
				...params,
			}
			let res = await toGetBucketName(payload)
			if (res.status === 200) {
				return res.data.response
			} else {
				return []
			}
		} catch (error) {
			console.log(error)
			return error
		}
	})
}

export function useLoadAssetTypeList() {
	return useMutation(async params => {
		try {
			let payload = {
				...getLocalAuthDetails(),
				...params,
			}
			let res = await loadAssetTypeList(payload)
			if (res.status === 200) {
				return res.data.response
			} else {
				return []
			}
		} catch (error) {
			console.log(error)
			return error
		}
	})
}

export function useCreateCustomerAsset() {
	return useMutation(async params => {
		try {
			let payload = {
				...params,
			}
			console.log('payload', payload)
			let res = await createCustomerAsset(payload)
			if (res.status === 200) {
				if (res.data.response) {
					return res.data.response
				} else {
					return {}
				}
			} else {
				throw new Error('Something went wrong!')
			}
		} catch (error) {
			return error
		}
	})
}
