import { useMutation } from 'react-query';
import {
  cognitoSignIn,
  cognitoSignOut,
  cognitoValidateOtp,
  verifyCognitoChallenge,
  updateLastSeen,
} from '../services/login.service';

export function useCognitoSignIn() {
  return useMutation(async payload => {
    try {
      const { assetId, encToken } = payload;
      const user = await cognitoSignIn(payload);
      if (!!user) {
        const challengeRes = await verifyCognitoChallenge({
          user,
          pwd: `${assetId}|${encToken}`,
        });
        if (challengeRes) {
          return user.signInUserSession;
        } else {
          throw new Error('Invalid crendentials!');
        }
      } else {
        throw new Error('No user found!');
      }
    } catch (err) {
      throw new Error(err.message);
    }
  });
}

export function useLogOut() {
  return useMutation(payload => cognitoSignOut(payload));
}

export function useSendCognitoOtp() {
  return useMutation(payload => cognitoSignIn(payload));
}

export function useValidateOtp() {
  return useMutation(payload => cognitoValidateOtp(payload));
}

export function useLastSeenUpdate() {
  return useMutation(async payload => {
    try {
      let requestData = {
        ...payload,
      };
      const res = await updateLastSeen(requestData);
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      console.log(error);
      throw new Error(error.message);
    }
  });
}
