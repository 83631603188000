import { useMutation } from 'react-query';
import { getLocalAuthDetails } from '../utils/common.util';
import { loadTeamMembers, handleSuspension } from '../services/team.service';

export function useGetTeamMembers() {
  return useMutation(async (params = {}) => {
    try {
      if (!!params) {
        const {
          page_start,
          page_limit,
          start_from,
          limit_value,
          flag,
          user_asset_id,
        } = params;
        let userData = getLocalAuthDetails();
        let payload = {
          ...userData,
          page_start: page_start,
          page_limit: page_limit,
          start_from: start_from,
          limit_value: limit_value,
          flag: flag,
          log_asset_id: userData.asset_id,
          manager_asset_id: user_asset_id,
        };
        let res = await loadTeamMembers(payload);
        return res.data.response;
      }
    } catch (error) {
      return error;
    }
  });
}

export function useHandleSuspension() {
  return useMutation(async (params = {}) => {
    try {
      let userData = getLocalAuthDetails();
      let payload = {
        ...params,
        ...userData,
        log_asset_id: userData.asset_id,
      };
      let res = await handleSuspension(payload);
      return res.data.response;
    } catch (error) {
      return error;
    }
  });
}
