import { useMutation } from 'react-query';
import { getLocalAuthDetails } from '../utils/common.util';
import {
  loadLeaveList,
  loadLeaveEdit,
  loadAddLeave,
  loadLeaveDelete,
} from '../services/leave.service';

export function useLeaveListData() {
  return useMutation(async (params = {}) => {
    let userData = getLocalAuthDetails();
    try {
      let payload = {
        ...userData,
        target_asset_id: userData.asset_id,
        page_start: 0,
        page_limit: 30,
      };
      let res = await loadLeaveList(payload);
      return res;
    } catch (error) {
      return error;
    }
  });
}
export function useLeaveApplyData() {
  return useMutation(async (params = {}) => {
    let userData = getLocalAuthDetails();
    try {
      let payload = {
        ...userData,
        target_asset_id: userData.asset_id,
        ...params,
        page_start: 0,
        page_limit: 30,
      };
      let res = await loadAddLeave(payload);
      return res;
    } catch (error) {
      return error;
    }
  });
}
export function useLeaveDeleteData() {
  return useMutation(async (params = {}) => {
    let userData = getLocalAuthDetails();
    try {
      let payload = {
        ...userData,
        target_asset_id: userData.asset_id,
        ...params,
        page_start: 0,
        page_limit: 30,
      };
      let res = await loadLeaveDelete(payload);
      return res;
    } catch (error) {
      return error;
    }
  });
}
export function useLeaveEditData() {
  return useMutation(async (params = {}) => {
    let userData = getLocalAuthDetails();
    try {
      let payload = {
        ...userData,
        target_asset_id: userData.asset_id,
        ...params,
        page_start: 0,
        page_limit: 30,
      };
      let res = await loadLeaveEdit(payload);
      return res;
    } catch (error) {
      return error;
    }
  });
}
