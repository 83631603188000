import { useMutation } from 'react-query';
import { getLocalAuthDetails } from '../utils/common.util';
import { loadVidmList, loadSearchBcDocs } from '../services/vidm.service';

export function useGetVidmDocs() {
  return useMutation(async (params = {}) => {
    try {
      let userData = getLocalAuthDetails();
      let payload = {
        ...params,
        ...userData,
      };
      let res = await loadVidmList(payload);
      return res;
    } catch (error) {
      return error;
    }
  });
}

export function useGetSearchBCDocs() {
  return useMutation(async (params = {}) => {
    try {
      let userData = getLocalAuthDetails();
      let payload = {
        ...params,
        ...userData,
      };
      let res = await loadSearchBcDocs(payload);
      return res;
    } catch (error) {
      return error;
    }
  });
}
