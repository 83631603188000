import { useMutation } from 'react-query'
import { getLocalAuthDetails } from '../utils/common.util'
import {
	getISTtoUTC,
	getDateISTFormat,
	getCurrentDateUTCEOD,
	getCurrentDateUTC,
	getCurrentDateUTCSTART,
} from '../utils/date.utils'
import {
	loadReportData,
	loadReportTypeList,
	loadReportTypeLists,
	loadReportFilterList,
	loadReportProcessTypeList,
	addReport,
	updateDownloadCount,
} from '../services/report.service'

export function useLoadReportData() {
	return useMutation(async params => {
		const userData = getLocalAuthDetails()
		try {
			const payload = {
				...userData,
				target_asset_id: userData.asset_id,
				report_type_id: params?.report_type_id || 0,
				date_type: params?.date_type,
				start_datetime: getDateISTFormat(params?.start_datetime),
				end_datetime: getCurrentDateUTC(params?.end_datetime),
				page_start: params?.page_start || 0,
				page_limit: params?.page_limit || 50,
			}
			const res = await loadReportData(payload)
			return res.data.response
		} catch (error) {
			return error
		}
	})
}

export function useLoadReportTypeList() {
	return useMutation(async () => {
		let userData = getLocalAuthDetails()
		try {
			let payload = {
				...userData,
				target_asset_id: userData.asset_id,
				access_level_id: 0,
				tag_type_id: 0,
				tag_id: 0,
				activity_type_id: 0,
				target_account_id: 0,
				search_string: '',
				is_export: 0,
				page_start: 0,
				page_limit: 50,
			}
			let res = await loadReportTypeList(payload)
			const resp = res.data.response
			let reports = []
			if (resp.length) {
				reports = resp.map(report => {
					return {
						value: report.report_type_id,
						label: report.report_type_name,
						tag_type_id: report.tag_type_id,
					}
				})
			} else {
				reports = [{ value: -1, label: 'NA' }]
			}
			return reports
		} catch (error) {
			return error
		}
	})
}
export function useLoadReportTypeLists() {
	return useMutation(async () => {
		let userData = getLocalAuthDetails()
		try {
			let payload = {
				...userData,
				start_from: 0,
				limit_value: 5000,
			}
			let res = await loadReportTypeLists(payload)
			const resp = res.data.response
			let reports = []
			if (resp.length) {
				reports = resp.map(report => {
					return {
						value: report.report_type_id,
						label: report.report_type_name,
						tag_type_id: report.report_type_id,
					}
				})
				reports.unshift({
					value: 0,
					label: 'All',
					tag_type_id: 0,
				})
			} else {
				reports = [{ value: null, label: 'NA' }]
			}
			return reports
		} catch (error) {
			return error
		}
	})
}
export function useLoadReportClusterList() {
	return useMutation(async () => {
		let userData = getLocalAuthDetails()
		try {
			let payload = {
				...userData,
				access_level_id: 25,
				target_asset_id: userData.asset_id,
				tag_type_id: 0,
				tag_id: 0,
				activity_type_id: 0,
				target_account_id: 0,
				search_string: '',
				is_export: 1,
				page_start: 0,
				page_limit: 50,
			}
			let res = await loadReportTypeList(payload)
			const resp = res.data.response
			let clusters = []
			if (resp.length) {
				clusters = resp.map(cluster => {
					return {
						value: cluster.cluster_tag_id,
						label: cluster.cluster_tag_name,
					}
				})
				// if (!clusters.find(item => item.value === 0) && clusters.length > 1) {
				//   clusters.unshift({ value: -1, label: 'My Clusters' });
				// }
			} else {
				clusters = [{ value: -1, label: 'NA' }]
			}
			return clusters
		} catch (error) {
			return error
		}
	})
}

export function useLoadReportGeographyList() {
	return useMutation(async cluster => {
		let userData = getLocalAuthDetails()
		try {
			// if(cluster === -1){
			//   return [{ value: -1, label: 'My Circles' }];
			// }
			let payload = {
				...userData,
				access_level_id: 2,
				target_asset_id: userData.asset_id,
				tag_type_id: 0,
				tag_id: cluster,
				activity_type_id: 0,
				target_account_id: 0,
				search_string: '',
				is_export: 1,
				page_start: 0,
				page_limit: 50,
			}
			let res = await loadReportTypeList(payload)
			const resp = res.data.response
			let buildings = []
			if (resp.length) {
				buildings = resp.map(building => {
					return {
						value: building.account_id,
						label: building.account_name,
					}
				})
				// if (!buildings.find(item => item.value === 0) && buildings.length > 1) {
				//   buildings.unshift({ value: -1, label: 'My Circles' });
				// }
			} else {
				buildings = [{ value: -1, label: 'NA' }]
			}
			return buildings
		} catch (error) {
			return error
		}
	})
}

export function useLoadReportFilterList() {
	return useMutation(async ({ tag_type_id = 0, report_id = 0 }) => {
		try {
			const payload = {
				...getLocalAuthDetails(),
				tag_type_id: tag_type_id,
				report_type_id: report_id,
				is_export: 2,
			}

			const res = await loadReportFilterList(payload)
			return res.data.response
		} catch (error) {
			return error
		}
	})
}

export function useReportProcessList() {
	return useMutation(async tag_type_id => {
		const { organization_id } = getLocalAuthDetails()
		try {
			let payload = {
				organization_id,
				tag_type_category_id: 0,
				tag_type_id,
				page_start: 0,
				page_limit: 100,
			}
			let res = await loadReportProcessTypeList(payload)
			const resp = res.data.response
			let processList = []
			if (resp.length) {
				processList = resp.map(process => {
					return {
						value: process.activity_type_id,
						label: process.activity_type_name,
					}
				})
			} else {
				processList = [{ value: -1, label: 'NA' }]
			}
			return processList
		} catch (error) {
			return error
		}
	})
}

export function useReportRolesList() {
	return useMutation(async filter => {
		try {
			const roleOptions = await JSON.parse(filter.filter_inline_data)
			return roleOptions
		} catch (error) {
			return error
		}
	})
}

export function useReportTimeScaleList() {
	return useMutation(async filter => {
		try {
			const timeScaleOptions = await JSON.parse(filter.filter_inline_data)
			return timeScaleOptions
		} catch (error) {
			return error
		}
	})
}

export function useAddReport() {
	return useMutation(async reportfilterValues => {
		try {
			const userData = getLocalAuthDetails()
			const {
				selectedReport,
				selectedProcess,
				startDateTime,
				endDateTime,
				selectedCluster,
				selectedBuilding,
				selectedRole,
			} = reportfilterValues
			const reportInlineJson = {
				report_name: reportfilterValues?.selectedReport.label,
				process_name:
					!!selectedProcess && ![17, 18].includes(selectedReport.value)
						? selectedProcess.label
						: 'NA',
				processes:
					!!selectedProcess && ![17, 18].includes(selectedReport.value)
						? selectedProcess.value
						: 0,
				role: !!selectedRole ? selectedRole.value : 0,
				role_name: !!selectedRole ? selectedRole.label : 0,
				geography: !!selectedBuilding ? selectedBuilding.value : 0,
				geography_name: !!selectedBuilding ? selectedBuilding.label : 0,
				cluster: !!selectedCluster ? selectedCluster.value : 0,
				cluster_name: !!selectedCluster ? selectedCluster.label : 0,
				form_names: [''],
			}

			let payload = {
				...userData,
				target_asset_id: 0,
				report_type_id: reportfilterValues?.selectedReport.value,
				report_inline_data: JSON.stringify(reportInlineJson),
				report_recursive_enabled: 0,
				report_notified_enabled: 1,
				report_recursive_type_id: 0,
				report_access_level_id: 1,
				activity_id: 0,
				report_start_time: null,
				report_end_time: null,
				report_start_datetime: getDateISTFormat(startDateTime),
				report_end_datetime: getCurrentDateUTCEOD(endDateTime),
			}

			let res = await addReport(payload)
			return res.data.status
		} catch (error) {
			return error
		}
	})
}

export function useUpdateDownloadCount() {
	return useMutation(async params => {
		let userData = getLocalAuthDetails()
		try {
			let payload = {
				...userData,
				...params,
			}
			const res = updateDownloadCount(payload)
			return res
		} catch (error) {
			return error
		}
	})
}
