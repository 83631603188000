import { useMutation } from 'react-query';
import { getLocalAuthDetails } from '../utils/common.util';
import { arpAssetAlter } from './../services/workhours.service';

export function useArpAssetAlter() {
  return useMutation(async (params = {}) => {
    let userData = getLocalAuthDetails();
    try {
      let payload = {
        ...userData,
        ...params,
        log_asset_id: userData.asset_id,
      };
      let res = await arpAssetAlter(payload);
      return res;
    } catch (error) {
      return error;
    }
  });
}
