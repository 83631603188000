// import { ColorModeScript } from '@chakra-ui/react';
import React, { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
// import { initializeTracking } from './containers/repository/repo.utils'
import * as serviceWorker from './serviceWorker'

import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { msalConfig } from './authConfig'

// Jul 2023 onwards Tracking ID will be removed
// ;(function initGAnalytics() {
// 	initializeTracking()
// })()

const msalInstance = new PublicClientApplication(msalConfig)
const root = createRoot(document.getElementById('root'))

root.render(
	<StrictMode>
		{/* <ColorModeScript /> */}
		<MsalProvider instance={msalInstance}>
			<App />
		</MsalProvider>
	</StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorker.unregister();
