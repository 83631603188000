import { useMutation } from 'react-query';
import { getLocalAuthDetails, getMessageUniqueId } from '../utils/common.util';
import {
  getAddToCurrentDateUTC,
  getCurrentDateUTC,
  getCurrentDateUTCSTART,
  getCurrentDate,
} from '../utils/date.utils';
import {
  saveProjectBaseline,
  getMappingActivity,
  loadScheduledData,
  loadBaselineData,
} from '../services/gantt.services';

export const useSaveBaseline = () => {
  return useMutation(async (params = {}) => {
    try {
      if (!!params) {
        let userData = getLocalAuthDetails();
        const requestData = {
          ...userData,
          ...params,
          log_asset_id: userData.asset_id,
          log_datetime: getCurrentDate(),
          snapshot_datetime: getCurrentDate(),
        };
        let res = await saveProjectBaseline(requestData);
        return res.data ? res.data.response : [];
      }
    } catch (error) {
      console.log('Error', error);
      return error;
    }
  });
};

export const useGetMappingActivity = () => {
  return useMutation(async (params = {}) => {
    try {
      if (!!params) {
        const requestData = {
          ...getLocalAuthDetails(),
          ...params,
        };
        let res = await getMappingActivity(requestData);
        return res.data ? res.data.response : [];
      }
    } catch (error) {
      console.log('Error', error);
      return error;
    }
  });
};

export const useLoadScheduledData = () => {
  return useMutation(async (params = {}) => {
    try {
      if (!!params) {
        const requestData = {
          ...getLocalAuthDetails(),
          ...params,
        };
        let res = await loadScheduledData(requestData);
        return res.data ? res.data.response : [];
      }
    } catch (error) {
      console.log('Error', error);
      return error;
    }
  });
};

export const useLoadBaselineData = () => {
  return useMutation(async (params = {}) => {
    try {
      if (!!params) {
        const requestData = {
          ...getLocalAuthDetails(),
          ...params,
        };
        let res = await loadBaselineData(requestData);
        return res.data ? res.data.response : [];
      }
    } catch (error) {
      console.log('Error', error);
      return error;
    }
  });
};
